import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import {
  Box,
  BoxProps,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropsType = {
  message?: string;
  placement?: PlacementWithLogical;
  isDisabled: boolean;
  children: ReactNode;
} & BoxProps;

const PopoverMessageWrapper = (props: PropsType) => {
  const { isMobile } = useScreenInfos();
  const { message = '', isDisabled, children, width, placement, ...rest } = props;

  return isDisabled ? (
    <Popover trigger={isMobile ? 'click' : 'hover'} placement={placement} isLazy>
      <PopoverTrigger>
        <Box
          position='relative'
          role='button'
          tabIndex={0}
          width={width}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        >
          <Box
            cursor='not-allowed'
            position='absolute'
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1}
          />
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent w='max-content'>
        <PopoverBody
          bgColor='gray.700'
          textColor='whiteAlpha.900'
          borderRadius='sm'
          fontSize='sm'
          px={2}
          py={0.5}
        >
          {message}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    <Box position='relative' role='button' tabIndex={0} width={width}>
      {children}
    </Box>
  );
};

export default PopoverMessageWrapper;
